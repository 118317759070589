import { default as indexCuNBdRs7tNMeta } from "/home/ubuntu/adificial-content-player/pages/embed/[[embedContentType]]/[contentId]/index.vue?macro=true";
import { default as indexqzQCgnq8HVMeta } from "/home/ubuntu/adificial-content-player/pages/index.vue?macro=true";
import { default as indexNjOBv2vGwyMeta } from "/home/ubuntu/adificial-content-player/pages/preview/[landingPageId]/[purlId]/index.vue?macro=true";
import { default as indexpok4KkpH7oMeta } from "/home/ubuntu/adificial-content-player/pages/preview/chapter/[id]/index.vue?macro=true";
import { default as indexsJ6wt2VvJwMeta } from "/home/ubuntu/adificial-content-player/pages/preview/landingPage/[landingId]/index.vue?macro=true";
import { default as _91sceneIndex_93sbuHAbCzyYMeta } from "/home/ubuntu/adificial-content-player/pages/preview/poster/[purlId]/[sceneIndex].vue?macro=true";
import { default as _91chapterId_93uLoCpOYEW4Meta } from "/home/ubuntu/adificial-content-player/pages/preview/poster2/[chapterId].vue?macro=true";
import { default as _91sceneId_93zrRcqoYYsnMeta } from "/home/ubuntu/adificial-content-player/pages/preview/sceneLayout/[sceneId].vue?macro=true";
import { default as _91sceneIndex_93GS3IVqSSCaMeta } from "/home/ubuntu/adificial-content-player/pages/preview/thumbnail/[chapterId]/[sceneIndex].vue?macro=true";
import { default as indexVXbgKjGQqaMeta } from "/home/ubuntu/adificial-content-player/pages/PURL/[landingId]/[purlId]/index.vue?macro=true";
import { default as index0D9rqytsa4Meta } from "/home/ubuntu/adificial-content-player/pages/PURL/[landingId]/index.vue?macro=true";
import { default as _91chapterID_93XBFcysFlBOMeta } from "/home/ubuntu/adificial-content-player/pages/video/chapter/[chapterID].vue?macro=true";
import { default as _91chapterID_93piyQbgNIbKMeta } from "/home/ubuntu/adificial-content-player/pages/video/test/[chapterID].vue?macro=true";
export default [
  {
    name: indexCuNBdRs7tNMeta?.name ?? "embed-embedContentType-contentId",
    path: indexCuNBdRs7tNMeta?.path ?? "/embed/:embedContentType?/:contentId()",
    meta: indexCuNBdRs7tNMeta || {},
    alias: indexCuNBdRs7tNMeta?.alias || [],
    redirect: indexCuNBdRs7tNMeta?.redirect,
    component: () => import("/home/ubuntu/adificial-content-player/pages/embed/[[embedContentType]]/[contentId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqzQCgnq8HVMeta?.name ?? "index",
    path: indexqzQCgnq8HVMeta?.path ?? "/",
    meta: indexqzQCgnq8HVMeta || {},
    alias: indexqzQCgnq8HVMeta?.alias || [],
    redirect: indexqzQCgnq8HVMeta?.redirect,
    component: () => import("/home/ubuntu/adificial-content-player/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNjOBv2vGwyMeta?.name ?? "preview-landingPageId-purlId",
    path: indexNjOBv2vGwyMeta?.path ?? "/preview/:landingPageId()/:purlId()",
    meta: indexNjOBv2vGwyMeta || {},
    alias: indexNjOBv2vGwyMeta?.alias || [],
    redirect: indexNjOBv2vGwyMeta?.redirect,
    component: () => import("/home/ubuntu/adificial-content-player/pages/preview/[landingPageId]/[purlId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpok4KkpH7oMeta?.name ?? "preview-chapter-id",
    path: indexpok4KkpH7oMeta?.path ?? "/preview/chapter/:id()",
    meta: indexpok4KkpH7oMeta || {},
    alias: indexpok4KkpH7oMeta?.alias || [],
    redirect: indexpok4KkpH7oMeta?.redirect,
    component: () => import("/home/ubuntu/adificial-content-player/pages/preview/chapter/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexsJ6wt2VvJwMeta?.name ?? "preview-landingPage-landingId",
    path: indexsJ6wt2VvJwMeta?.path ?? "/preview/landingPage/:landingId()",
    meta: indexsJ6wt2VvJwMeta || {},
    alias: indexsJ6wt2VvJwMeta?.alias || [],
    redirect: indexsJ6wt2VvJwMeta?.redirect,
    component: () => import("/home/ubuntu/adificial-content-player/pages/preview/landingPage/[landingId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91sceneIndex_93sbuHAbCzyYMeta?.name ?? "preview-poster-purlId-sceneIndex",
    path: _91sceneIndex_93sbuHAbCzyYMeta?.path ?? "/preview/poster/:purlId()/:sceneIndex()",
    meta: _91sceneIndex_93sbuHAbCzyYMeta || {},
    alias: _91sceneIndex_93sbuHAbCzyYMeta?.alias || [],
    redirect: _91sceneIndex_93sbuHAbCzyYMeta?.redirect,
    component: () => import("/home/ubuntu/adificial-content-player/pages/preview/poster/[purlId]/[sceneIndex].vue").then(m => m.default || m)
  },
  {
    name: _91chapterId_93uLoCpOYEW4Meta?.name ?? "preview-poster2-chapterId",
    path: _91chapterId_93uLoCpOYEW4Meta?.path ?? "/preview/poster2/:chapterId()",
    meta: _91chapterId_93uLoCpOYEW4Meta || {},
    alias: _91chapterId_93uLoCpOYEW4Meta?.alias || [],
    redirect: _91chapterId_93uLoCpOYEW4Meta?.redirect,
    component: () => import("/home/ubuntu/adificial-content-player/pages/preview/poster2/[chapterId].vue").then(m => m.default || m)
  },
  {
    name: _91sceneId_93zrRcqoYYsnMeta?.name ?? "preview-sceneLayout-sceneId",
    path: _91sceneId_93zrRcqoYYsnMeta?.path ?? "/preview/sceneLayout/:sceneId()",
    meta: _91sceneId_93zrRcqoYYsnMeta || {},
    alias: _91sceneId_93zrRcqoYYsnMeta?.alias || [],
    redirect: _91sceneId_93zrRcqoYYsnMeta?.redirect,
    component: () => import("/home/ubuntu/adificial-content-player/pages/preview/sceneLayout/[sceneId].vue").then(m => m.default || m)
  },
  {
    name: _91sceneIndex_93GS3IVqSSCaMeta?.name ?? "preview-thumbnail-chapterId-sceneIndex",
    path: _91sceneIndex_93GS3IVqSSCaMeta?.path ?? "/preview/thumbnail/:chapterId()/:sceneIndex()",
    meta: _91sceneIndex_93GS3IVqSSCaMeta || {},
    alias: _91sceneIndex_93GS3IVqSSCaMeta?.alias || [],
    redirect: _91sceneIndex_93GS3IVqSSCaMeta?.redirect,
    component: () => import("/home/ubuntu/adificial-content-player/pages/preview/thumbnail/[chapterId]/[sceneIndex].vue").then(m => m.default || m)
  },
  {
    name: indexVXbgKjGQqaMeta?.name ?? "PURL-landingId-purlId",
    path: indexVXbgKjGQqaMeta?.path ?? "/PURL/:landingId()/:purlId()",
    meta: indexVXbgKjGQqaMeta || {},
    alias: indexVXbgKjGQqaMeta?.alias || [],
    redirect: indexVXbgKjGQqaMeta?.redirect,
    component: () => import("/home/ubuntu/adificial-content-player/pages/PURL/[landingId]/[purlId]/index.vue").then(m => m.default || m)
  },
  {
    name: index0D9rqytsa4Meta?.name ?? "PURL-landingId",
    path: index0D9rqytsa4Meta?.path ?? "/PURL/:landingId()",
    meta: index0D9rqytsa4Meta || {},
    alias: index0D9rqytsa4Meta?.alias || [],
    redirect: index0D9rqytsa4Meta?.redirect,
    component: () => import("/home/ubuntu/adificial-content-player/pages/PURL/[landingId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91chapterID_93XBFcysFlBOMeta?.name ?? "video-chapter-chapterID",
    path: _91chapterID_93XBFcysFlBOMeta?.path ?? "/video/chapter/:chapterID()",
    meta: _91chapterID_93XBFcysFlBOMeta || {},
    alias: _91chapterID_93XBFcysFlBOMeta?.alias || [],
    redirect: _91chapterID_93XBFcysFlBOMeta?.redirect,
    component: () => import("/home/ubuntu/adificial-content-player/pages/video/chapter/[chapterID].vue").then(m => m.default || m)
  },
  {
    name: _91chapterID_93piyQbgNIbKMeta?.name ?? "video-test-chapterID",
    path: _91chapterID_93piyQbgNIbKMeta?.path ?? "/video/test/:chapterID()",
    meta: _91chapterID_93piyQbgNIbKMeta || {},
    alias: _91chapterID_93piyQbgNIbKMeta?.alias || [],
    redirect: _91chapterID_93piyQbgNIbKMeta?.redirect,
    component: () => import("/home/ubuntu/adificial-content-player/pages/video/test/[chapterID].vue").then(m => m.default || m)
  }
]